import HttpUtils from '../utils/HttpUtils'

async function list(data?:any) {
    const params= {
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
        type:data.type,
        status:data.status,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerList',params);
}

async function getMinerListByOwner(data?:any) {
    const params= {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerListByOwner',params);
}

async function getMinerByAddress(address:string) {
    const params= {
        address:address
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerByAddress',params);
}

async function getMinerListByPlant(address:string,offset:number|undefined,limit:number|undefined) {
    const params= {
        address:address,
        offset:offset?offset:1,
        limit:limit?limit:10
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerListByPlant',params);
}

async function getPlantInfoByMiner(address:string) {
    const params= {
        minerAddress:address
    }
    return await HttpUtils.sendJsonRPC('/v1','svc_plant_byminer',params);
}

const MinerApi ={
    list:list,
    getPlantInfoByMiner:getPlantInfoByMiner,
    getMinerListByOwner:getMinerListByOwner,
    getMinerByAddress:getMinerByAddress,
    getMinerListByPlant:getMinerListByPlant,
}

export default MinerApi;